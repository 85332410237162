import { Button, Container } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import logoGolden from "assets/logo.svg"
import { useStateContext } from "context/Context"

const FirstGrid = () => {
  const { loading, activeHome, swiperMobileHome } = useStateContext()

  const isActive =
    (activeHome.active === 0 || activeHome.prev === 0) && !loading

  return (
    <Box
      className="height-100"
      sx={{
        color: "secondary.main",
        bgcolor: "primary.main",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexDirection: "column",
          height: "100%",
          pt: 2,
          pb: 0.5,
        }}
      >
        <Box>
          <img
            className={` ${
              isActive
                ? "animate__animated animate__fadeInLeft animate__delay-1s"
                : ""
            }`}
            src={logoGolden}
            alt="logo"
            style={{
              height: "35px",
            }}
          />
        </Box>

        <Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              variant="subtitle1"
              gutterBottom
            >
              CONCEPT TO CREATION
            </Typography>
          </Box>

          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              sx={{
                typography: { xs: "h4", sm: "h2" },
              }}
            >
              Innovators of
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              sx={{
                typography: { xs: "h4", sm: "h2" },
              }}
              gutterBottom
            >
              Software solutions
            </Typography>
          </Box>
          <Box p={1} />
          <Box
            sx={{
              overflow: "hidden",
              py: 0.5,
            }}
          >
            <Button
              onClick={() => swiperMobileHome.slideTo(4)}
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              variant="contained"
              color="secondary"
              size="large"
              sx={{
                // opacity: 0,
                fontWeight: 800,
                // overflow: 'hidden',
                // transform: 'translateY(150px)',
                background: "var(--gradient)",
                // '& span': {
                //   position: 'relative',
                //   zIndex: 2,
                // },
              }}
            >
              <span>Our Services</span>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <a href="mailto:contact@qucode.tech">
              <Typography
                className={` ${
                  isActive
                    ? "animate__animated animate__fadeInLeft animate__delay-1s text-gradient"
                    : "text-gradient"
                }`}
                align="left"
                variant="h6"
                sx={{
                  fontWeight: "500 !important",
                }}
              >
                contact@qucode.tech
              </Typography>
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default FirstGrid
