import { Button, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

const SingleLeft = ({ title }) => {
  return (
    <Box
      sx={{
        height: { xs: '100svh', sm: '100vh' },
        width: '100%',
        // bgcolor: 'aquamarine',
        backgroundSize: 'cover',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        alignItems="stretch"
        sx={{
          width: '100%',
          bgcolor: 'primary.main',
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              height: { xs: '50svh', sm: '100svh' },
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transition: 'all 0.5s ease-in-out',
            }}
          >
            <Button
              variant="contained"
              className="gradient left"
              sx={{
                '& span': {
                  position: 'relative',
                  zIndex: 2,
                },
                transition: 'all 0.5s ease-in-out',
              }}
              color="secondary"
              size="large"
            >
              <Typography
                sx={{
                  transition: 'all 0.5s ease-in-out',
                }}
                align="center"
                variant="h4"
              >
                <span>Contact Us</span>
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            width: '100%',
            background: 'var(--gradient)',
          }}
        >
          <Box
            sx={{
              height: { xs: '50svh', sm: '100svh' },
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',

              zIndex: 2,
            }}
          >
            <Button variant="outlined" color="primary" size="large">
              <Typography align="center" variant="h4">
                Become a Member
              </Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default SingleLeft
