import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import one from 'assets/Home/one.svg'

const TopGrid = ({ isActive }) => {
  return (
    <Box
      className="height-50"
      sx={{
        width: '100%',
        bgcolor: 'var(--primary)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'secondary.main',
        '& img': {
          height: 60,
        },
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={one} alt="one" />
        <br />
        <Typography variant="h6" align="center" gutterBottom fontWeight="bold">
          E-Commerce <br /> Development
        </Typography>
        <Typography align="center" variant="subtitle2">
          Services include website design & development, payment gateway
          integration, inventory management, order tracking etc.
        </Typography>
      </Container>
    </Box>
  )
}

export default TopGrid
