import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useSwiper } from 'swiper/react'

const FocusArea = () => {
  const swiper = useSwiper()
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          align="left"
          variant="subtitle2"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& span': {
              fontSize: 25,
              mr: 1,
              mb: 0.3,
            },
          }}
        >
          <span>•</span> COMPANY
        </Typography>
        <Box
          sx={{
            color: 'grey',
          }}
        >
          <Typography
            onClick={() => swiper.slideTo(1)}
            sx={{
              color: 'grey',
              '&:hover': {
                color: 'var(--golden)',
              },
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',
            }}
            align="left"
            variant="h6"
          >
            About Us
          </Typography>
          <Typography
            onClick={() => swiper.slideTo(2)}
            sx={{
              color: 'grey',
              '&:hover': {
                color: 'var(--golden)',
              },
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',
            }}
            align="left"
            variant="h6"
          >
            Our Service
          </Typography>
          <Typography
            onClick={() => swiper.slideTo(3)}
            sx={{
              color: 'grey',
              '&:hover': {
                color: 'var(--golden)',
              },
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',
            }}
            align="left"
            variant="h6"
          >
            Contact Us
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default FocusArea
