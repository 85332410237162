import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import BottomRight from './BottomRight'
import TopRight from './TopRight'

const RightGrid = ({ isActive }) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <Grid
        container
        flexDirection="column"
        sx={{
          height: '100%',
        }}
      >
        <Grid item xs>
          <TopRight isActive={isActive} />
        </Grid>
        <Grid item xs>
          <BottomRight isActive={isActive} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default RightGrid
