import About from 'pages/About'
import Contact from 'pages/Contact'
import Home from 'pages/Home'
import Project from 'pages/Project'
import { Route, Routes } from 'react-router-dom'

const AppRoutes = () => {
  return (
    <main>
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="project" element={<Project />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
    </main>
  )
}

export default AppRoutes
