import Box from '@mui/material/Box'
import { useStateContext } from 'context/Context'
import MobileSideBar from './MobileSideBar'

const MobileNavbar = ({ darkmode }) => {
  const { activeHome, setActiveHome } = useStateContext()

  return (
    <Box
      sx={{
        bgcolor: 'transparent',
      }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          right: 0,
          zIndex: 600,
          p: 0.5,
          flexGrow: 1,
          display: {
            xs: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          },
        }}
      >
        <MobileSideBar
          activeHome={activeHome.active}
          setActiveHome={setActiveHome}
        />
      </Box>
    </Box>
  )
}
export default MobileNavbar
