import CallIcon from "@mui/icons-material/Call"
import EmailIcon from "@mui/icons-material/Email"
import FmdGoodIcon from "@mui/icons-material/FmdGood"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import { Container } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
// import hasin from "assets/Contact/hasin.jpg"
// import newaz from "assets/Contact/newaz.png"
// import omar from "assets/Contact/omar.jpg"
// import rabbi from "assets/Contact/rabbi.jpg"
import bg from "assets/background.jpg"
import logoGolden from "assets/logo.svg"
import { useStateContext } from "context/Context"
// import LinkedinMobile from "./LinkedinMobile"

const CEOGrid = () => {
  const { activeHome, swiperMobileHome } = useStateContext()
  const isActive = activeHome.active === 8 || activeHome.prev === 8
  return (
    <Box
      className="height-100"
      sx={{
        width: "100%",
        bgcolor: "primary.main",
        color: "primary.contrastText",
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), 
          url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",

          height: "100%",
        }}
      >
        <Box
          onClick={() => swiperMobileHome.slideTo(0)}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            m: 2,
            zIndex: 1,
          }}
        >
          <img
            className={` ${
              isActive
                ? "animate__animated animate__fadeInLeft animate__delay-1s"
                : ""
            }`}
            src={logoGolden}
            alt="logo"
            style={{
              height: "35px",
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "start",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              sx={{
                fontSize: "1.8rem",
              }}
            >
              QuCode
            </Typography>
          </Box>

          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Box
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInLeft animate__delay-1s"
                  : ""
              }`}
              sx={{
                background: "var(--gradient)",
                mb: 2,
                width: 150,
                height: 3,
                bgcolor: "secondary.main",
              }}
            />
          </Box>
          <br />
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              variant="subtitle1"
              gutterBottom
              component="a"
              target="_blank"
              href="https://goo.gl/maps/3W1rngCZAnKozbMCA"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  color: "var(--golden)",
                },
              }}
            >
              <FmdGoodIcon
                sx={{
                  mr: 1,
                  color: "var(--golden)",
                }}
              />
              Khilgaon, Dhaka, Bangladesh
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  color: "var(--golden)",
                },
              }}
              align="left"
              variant="subtitle1"
              gutterBottom
              component="a"
              href="mailto:contact@qucode.tech"
              target="_blank"
            >
              <EmailIcon
                sx={{
                  mr: 1,
                  color: "var(--golden)",
                }}
              />
              contact@qucode.tech
            </Typography>
          </Box>

          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              variant="subtitle1"
              gutterBottom
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  color: "var(--golden)",
                },
              }}
              component="a"
              href="tel:+8801739700228"
            >
              <CallIcon
                sx={{
                  mr: 1,
                  color: "var(--golden)",
                }}
              />
              +8801739700228
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  color: "var(--golden)",
                },
              }}
              align="left"
              variant="subtitle1"
              gutterBottom
              component="a"
              href="https://www.linkedin.com/company/qucoders/"
              target="_blank"
            >
              <LinkedInIcon
                sx={{
                  mr: 1,
                  color: "var(--golden)",
                }}
              />
              Linkedin Profile
            </Typography>
          </Box>
          <br />
          <br />

          {/* <Grid
            container
            spacing={5}
            sx={{
              mt: 5,
            }}
          >
            <Grid item xs={6}>
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Box
                  className={` ${
                    isActive
                      ? "animate__animated animate__fadeInLeft animate__delay-1s"
                      : ""
                  }`}
                >
                  <LinkedinMobile
                    name="Mezbah"
                    img={rabbi}
                    job="DSC"
                    link="https://www.linkedin.com/in/mezbah-uddin-841483169/"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Box
                  className={` ${
                    isActive
                      ? "animate__animated animate__fadeInLeft animate__delay-1s"
                      : ""
                  }`}
                >
                  <LinkedinMobile
                    name="Newaz"
                    img={newaz}
                    job="CEO"
                    link="https://www.linkedin.com/in/saif-newaz"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Box
                  className={` ${
                    isActive
                      ? "animate__animated animate__fadeInLeft animate__delay-1s"
                      : ""
                  }`}
                  s
                >
                  <LinkedinMobile
                    name="Omar"
                    img={omar}
                    job="CTO"
                    link="https://www.linkedin.com/in/omar-hasan-79b64a125"
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Box
                  className={` ${
                    isActive
                      ? "animate__animated animate__fadeInLeft animate__delay-1s"
                      : ""
                  }`}
                >
                  <LinkedinMobile
                    name="Hasin"
                    img={hasin}
                    job="COO"
                    link="https://www.linkedin.com/in/hasin-ishraq-sajid/"
                  />
                </Box>
              </Box>
            </Grid>
          </Grid> */}
        </Box>
      </Container>
    </Box>
  )
}

export default CEOGrid
