import { Divider, Stack, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import ChooseBlock from './ChooseBlock'

const TopRight = ({ isActive }) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        p: { xs: 3, sm: 4 },
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          overflow: 'hidden',
          borderBottom: '1px solid lightgrey',
          width: '100%',
          mb: 3,
        }}
      >
        <Typography
          className={` ${
            isActive
              ? 'animate__animated animate__fadeInDown animate__delay-1s'
              : ''
          }`}
          align="center"
          sx={{
            typography: {
              xs: 'h5',
              sm: 'h4',
            },
            pb: 1,
            // fontWeight: '500 !important',
          }}
          gutterBottom
        >
          Why Choose Us?
        </Typography>
      </Box>
      <Stack
        sx={{
          height: '100%',
        }}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
        spacing={2}
      >
        <ChooseBlock
          isActive={isActive}
          title="Building custom-made software for individual clients."
        />

        <ChooseBlock
          isActive={isActive}
          title="Committed to provide high-quality software and apps."
        />

        <ChooseBlock
          isActive={isActive}
          title="Highly skilled engineers & excellent technical mastery."
        />
      </Stack>
      {/* <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            align="left"
            sx={{
              typography: {
                xs: 'h5',
                sm: 'h4',
              },

              // fontWeight: '500 !important',
            }}
            gutterBottom
          >
            Why Choose Us?
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
          >
            <ChooseBlock title="Building custom-made software for individual clients." />
          </Box>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
          >
            <ChooseBlock title="Committed to provide high-quality software and apps." />
          </Box>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
          >
            <ChooseBlock title="Highly skilled engineers & excellent technical mastery." />
          </Box>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
          >
            <ChooseBlock title="Always focus on providing excellent customer service." />
          </Box>
        </Box> */}

      {/* <Typography
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexDirection: 'column',
            '& span': {
              fontSize: 15,
              mr: 1,
            },
          }}
          align="left"
          variant="subtitle1"
          gutterBottom
        >
          <li>
            <span>•</span>Building custom-made software for individual clients
          </li>
          <li>
            <span>•</span>Committed to provide high-quality software and apps
          </li>
          <li>
            <span>•</span>Highly skilled engineers & excellent technical mastery
          </li>
          <li>
            <span>•</span>Always focus on providing excellent customer service.
          </li>
        </Typography> */}
      {/* <br /> */}
      {/* <Button
          className="gradient"
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            fontWeight: 800,
            '& span': {
              position: 'relative',
              zIndex: 2,
            },
          }}
        >
          <span>Download Doc</span>
        </Button> */}
      {/* </Box> */}
    </Box>
  )
}

export default TopRight
