import { CssBaseline, Hidden } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Navbar from 'components/Navbar'
import ContactNavbar from 'components/Navbar/Contact'
import ContactMobileNavbar from 'components/Navbar/Contact/MobileNav'
import MobileNavbar from 'components/Navbar/MobileNav'
import ProjectNavbar from 'components/Navbar/Project'
import ProjectMobileNavbar from 'components/Navbar/Project/MobileNav'
import { useStateContext } from 'context/Context'
import { useLocation } from 'react-router-dom'
import AppRoutes from 'Routes'
import Preloader from 'utils/Preloader'
import ScrollToTop from 'utils/ScrollToTop'

const App = () => {
  const { darkmode } = useStateContext()
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#131619',
      },
      secondary: {
        main: '#ffffff',
      },

      text: {
        primary: '#131619',
        secondary: '#DDDDDD',
      },
    },

    typography: {
      fontFamily: 'DM Sans',
      button: {
        textTransform: 'none',
      },
    },
  })
  const location = useLocation()
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop />
      <Preloader />
      <Hidden smDown>
        {location.pathname === '/' ? (
          <Navbar darkmode={darkmode} />
        ) : location.pathname === '/contact' ? (
          <ContactNavbar darkmode={darkmode} />
        ) : (
          <ProjectNavbar darkmode={darkmode} />
        )}
      </Hidden>
      <Hidden smUp>
        {location.pathname === '/' ? (
          <MobileNavbar darkmode={darkmode} />
        ) : location.pathname === '/contact' ? (
          <ContactMobileNavbar darkmode={darkmode} />
        ) : (
          <ProjectMobileNavbar darkmode={darkmode} />
        )}
      </Hidden>
      <AppRoutes />
    </ThemeProvider>
  )
}

export default App
