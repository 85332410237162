import Box from "@mui/material/Box"
import logoBlack from "assets/logoBlack.svg"
import { useStateContext } from "context/Context"
import { Link } from "react-router-dom"
import MobileSideBar from "./MobileSideBar"

const ContactMobileNavbar = ({ darkmode }) => {
  const { activeHome } = useStateContext()

  return (
    <Box
      sx={{
        bgcolor: "transparent",
      }}
    >
      <Box
        component={Link}
        to="/"
        sx={{
          zIndex: 400,
          position: "fixed",
          top: 0,
          left: 0,
          p: 2,
          display: "flex",
          fontWeight: 700,
          // color: 'inherit',
          textDecoration: "none",
          justifyContent: "center",
          alignItems: "center",
          transition: "all 0.5s ease-in-out",
          color: activeHome === 0 ? "secondary.main" : "primary.main",
        }}
      >
        <img
          src={logoBlack}
          alt="logo"
          style={{
            height: "35px",
          }}
        />
      </Box>

      <Box
        sx={{
          position: "fixed",
          top: 0,
          right: 0,
          zIndex: 600,
          p: 0.5,
          flexGrow: 1,
          display: {
            xs: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          },
        }}
      >
        <MobileSideBar activeHome={activeHome} />
      </Box>
    </Box>
  )
}
export default ContactMobileNavbar
