import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { useStateContext } from 'context/Context'
import LeftGrid from './LeftGrid'
import RightGrid from './RightGrid'

const ContactDesktop = () => {
  const { loading } = useStateContext()
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <LeftGrid isActive={!loading} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RightGrid isActive={!loading} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactDesktop
