import Box from '@mui/material/Box'
import { useStateContext } from 'context/Context'
import BottomGrid from './BottomGrid'
import TopGrid from './TopGrid'

const Services = () => {
  const { activeHome } = useStateContext()
  return (
    <Box
      className="height-100"
      sx={{
        bgcolor: 'secondary.main',
      }}
    >
      <TopGrid isActive={activeHome.active === 2 || activeHome.prev === 2} />

      <BottomGrid isActive={activeHome.active === 2 || activeHome.prev === 2} />
    </Box>
  )
}

export default Services
