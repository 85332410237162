import { Button, Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useStateContext } from 'context/Context'

const TopGrid = ({ isActive }) => {
  const { swiperMobileHome } = useStateContext()
  return (
    <Box
      className="height-50"
      sx={{
        background: 'var(--gradient)',
        width: '100%',
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          height: '100%',
          py: 2,
        }}
      >
        <Button
          onClick={() => swiperMobileHome.slideTo(0)}
          variant="outlined"
          color="primary"
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="h5"
            align="left"
            color="primary"
            fontWeight="bold"
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
          >
            Services
          </Typography>
        </Button>
        <Box>
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? 'animate__animated animate__fadeInDown animate__delay-1s'
                  : ''
              }`}
              align="left"
              variant="subtitle2"
              gutterBottom
            >
              FAST & OPTIMIZED
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? 'animate__animated animate__fadeInDown animate__delay-1s'
                  : ''
              }`}
              align="left"
              variant="h5"
              fontWeight="bold"
              gutterBottom
            >
              Custom Website <br /> Development
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? 'animate__animated animate__fadeInDown animate__delay-1s'
                  : ''
              }`}
              sx={{ maxWidth: '45ch' }}
              align="left"
              variant="subtitle2"
              gutterBottom
            >
              Our bespoke website development service offers a distinctive
              design that is meticulously tailored to reflect the client's brand
              and vision.
            </Typography>
          </Box>

          <Box
            sx={{
              overflow: 'hidden',
              pt: 2,
              pb: 0.5,
            }}
          >
            <Button
              className={` ${
                isActive
                  ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                  : ''
              }`}
              sx={{
                py: 0.7,
              }}
              variant="outlined"
              color="primary"
              size="large"
            >
              <Box
                sx={{
                  height: 2.5,
                  width: 80,
                  background: 'var(--primary)',
                }}
              />
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
export default TopGrid
