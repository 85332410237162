import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Box } from '@mui/material'
import { useStateContext } from 'context/Context'
import { useSwiper } from 'swiper/react'

const SlideControlMobile = () => {
  const swiper = useSwiper()
  const { activeHome } = useStateContext()
  return (
    <Box
      onClick={() => {
        activeHome.active === 8 ? swiper.slideTo(0) : swiper.slideNext()
      }}
      sx={{
        cursor: 'pointer',
        padding: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        position: 'fixed',
        bottom: 10,
        right: 10,
        zIndex: 1000,
        transition: 'all 0.3s ease-in-out',
        background:
          activeHome.active === 0
            ? 'var(--golden)'
            : activeHome.active === 1
            ? 'var(--primary)'
            : activeHome.active === 2
            ? 'var(--secondary)'
            : activeHome.active === 3
            ? 'var(--primary)'
            : activeHome.active === 4
            ? 'var(--secondary)'
            : activeHome.active === 5
            ? 'var(--primary)'
            : activeHome.active === 6
            ? 'var(--primary)'
            : activeHome.active === 7
            ? 'var(--primary)'
            : activeHome.active === 8
            ? 'var(--golden)'
            : activeHome.active === 9
            ? 'var(--golden)'
            : 'var(--golden)',
      }}
    >
      <KeyboardArrowDownIcon
        sx={{
          color:
            activeHome.active === 0
              ? 'var(--primary)'
              : activeHome.active === 1
              ? 'var(--secondary)'
              : activeHome.active === 2
              ? 'var(--primary)'
              : activeHome.active === 3
              ? 'var(--secondary)'
              : activeHome.active === 4
              ? 'var(--primary)'
              : activeHome.active === 5
              ? 'var(--secondary)'
              : activeHome.active === 6
              ? 'var(--secondary)'
              : activeHome.active === 7
              ? 'var(--secondary)'
              : activeHome.active === 8
              ? 'var(--primary)'
              : activeHome.active === 9
              ? 'var(--primary)'
              : 'var(--golden)',
          fontSize: '2rem',
          transition: 'all 0.3s ease-in-out',
          transform:
            activeHome.active === 8 ? 'rotate(180deg)' : 'rotate(0deg)',
        }}
      />
    </Box>
  )
}

export default SlideControlMobile
