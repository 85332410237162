import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import four from 'assets/Home/four.svg'
import one from 'assets/Home/one.svg'
import three from 'assets/Home/three.svg'
import two from 'assets/Home/two.svg'
import SingleBlack from './SingleBlack'
import SingleWhite from './SingleWhite'

const BottomGrid = ({ isActive }) => {
  return (
    <Box className="height-50">
      <Grid
        container
        sx={{
          height: '100%',
        }}
      >
        <Grid item xs={3}>
          <SingleBlack
            isActive={isActive}
            img={one}
            title={
              <span>
                E-Commerce <br /> Development
              </span>
            }
            desc="Services include website design & development, payment gateway integration, inventory management, order tracking etc."
          />
        </Grid>
        <Grid item xs={3}>
          <SingleWhite
            isActive={isActive}
            img={two}
            title={
              <span>
                Android/IOS <br />
                Applications
              </span>
            }
            desc="Android & iOS application services including design, development,
           testing, deployment, ongoing maintenance & support."
          />
        </Grid>
        <Grid item xs={3}>
          <SingleBlack
            isActive={isActive}
            img={three}
            title={
              <span>
                ERP Software <br />
                Solutions
              </span>
            }
            desc="Services include module for Finance, accounting, CRM, payroll
           software, human resources, supply chain management etc."
          />
        </Grid>
        <Grid item xs={3}>
          <SingleWhite
            isActive={isActive}
            img={four}
            title={
              <span>
                Cloud <br /> Services
              </span>
            }
            desc="Services including interface design, user research,information
            architecture, usability testing, information architecture etc."
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default BottomGrid
