import { Button, Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import img from 'assets/expertise.svg'
import { useStateContext } from 'context/Context'

const AboutGrid = () => {
  const { activeHome, swiperMobileHome } = useStateContext()
  const isActive = activeHome.active === 3 || activeHome.prev === 3
  return (
    <Box
      className="height-100"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        bgcolor: 'secondary.main',
      }}
    >
      <Container
        sx={{
          py: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={img} alt="img" width="80%" />
        </Box>
        <br />
        <br />
        <br />
        <Box>
          <Box>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                align="left"
                variant="h5"
                gutterBottom
                fontWeight="bold"
              >
                Expertise you can rely on
              </Typography>
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                align="left"
                variant="subtitle1"
                gutterBottom
              >
                Our expertise lies in leveraging the latest technologies and
                tools to create efficient, scalable, and secure software
                solutions that enhance business performance and improve customer
                experience. We have experience in a range of industries,
                including healthcare, finance, education, e-commerce, fashion
                and more.
              </Typography>
            </Box>
            <br />
            <Box
              sx={{
                overflow: 'hidden',
                py: 1,
              }}
            >
              <Button
                onClick={() => swiperMobileHome.slideTo(7)}
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                variant="outlined"
                color="primary"
                size="large"
              >
                Contact Us
              </Button>
            </Box>
          </Box>
          <Box />
        </Box>
      </Container>
    </Box>
  )
}

export default AboutGrid
