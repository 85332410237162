import Box from "@mui/material/Box"
import logo from "assets/logo.svg"

const Header = () => {
  return (
    <Box
      sx={{
        textAlign: "center",
        "& img": {
          // height: { xs: 80, sm: 120 },
          width: "60%",
        },
      }}
    >
      <img src={logo} alt="logo" />
    </Box>
  )
}

export default Header
