import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const SingleBlack = ({ img, title, desc, isActive }) => {
  return (
    <Box
      className={` ${
        isActive ? 'animate__animated animate__zoomIn animate__delay-1s' : ''
      }`}
      sx={{
        height: '100%',
        width: '100%',
        bgcolor: 'var(--primary)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: 'secondary.main',
        p: 3,
        '& img': {
          height: 60,
        },
      }}
    >
      <img src={img} alt="img" />
      <br />
      <Typography variant="h6" align="center" gutterBottom fontWeight="bold">
        {title}
      </Typography>
      <Typography align="center">{desc}</Typography>
    </Box>
  )
}

export default SingleBlack
