import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const Social = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          align="left"
          variant="subtitle2"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& span': {
              fontSize: 25,
              mr: 1,
              mb: 0.3,
            },
          }}
        >
          <span>•</span> SOCIAL
        </Typography>
        <Box
          sx={{
            color: 'grey',
          }}
        >
          <Typography
            sx={{
              color: 'grey',
              '&:hover': {
                color: 'var(--golden)',
              },
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',
            }}
            align="left"
            variant="h6"
          >
            Facebook
          </Typography>
          <Typography
            sx={{
              color: 'grey',
              '&:hover': {
                color: 'var(--golden)',
              },
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',
            }}
            align="left"
            variant="h6"
          >
            Whatsapp
          </Typography>
          <Typography
            sx={{
              color: 'grey',
              '&:hover': {
                color: 'var(--golden)',
              },
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out',
            }}
            align="left"
            variant="h6"
          >
            Linkedin
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default Social
