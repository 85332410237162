import { Button, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

const Single = ({ title, img, isActive }) => {
  return (
    <Box
      sx={{
        bgcolor: 'secondary.main',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '35vw',
          height: '100svh',
          background: 'var(--gradient)',
        }}
      />
      <Grid
        container
        spacing={3}
        alignItems="stretch"
        sx={{
          zIndex: 10,
          position: 'relative',
        }}
      >
        <Grid item xs={12} sm={7}>
          <Box
            sx={{
              height: '100svh',
              width: '100%',
              transition: 'all 0.5s ease-in-out',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '& img': {
                  width: '100%',
                },
              }}
            >
              <img
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInUp animate__delay-1s image'
                    : 'image'
                }`}
                src={img}
                alt="img"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flexDirection: 'column',
              height: '100svh',
            }}
          >
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                variant="subtitle1"
              >
                TECH TRENDS
              </Typography>
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                variant="h3"
                gutterBottom
              >
                <span className="text-gradient">{title}</span>
              </Typography>
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                gutterBottom
                variant="h5"
                sx={{
                  maxWidth: '25ch',
                }}
              >
                <span>
                  Look Towards the Future With Diggy, the App That Will Change
                  All. For a New Reality.
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
                p: 0.2,
              }}
            >
              <Button
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  mt: 1,
                  fontWeight: 800,
                  px: 5,
                  '& span': {
                    position: 'relative',
                    zIndex: 2,
                  },
                }}
              >
                <span>Visit Site</span>
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Single
