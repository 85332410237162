import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { useStateContext } from 'context/Context'
import LeftGrid from './LeftGrid'
import RightGrid from './RightGrid'

const Hero = () => {
  const { activeHome, loading } = useStateContext()
  return (
    <Box>
      <Grid container direction="row">
        <Grid item xs={12} sm={6}>
          <LeftGrid
            isActive={
              (activeHome.active === 0 || activeHome.prev === 0) && !loading
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RightGrid
            isActive={
              (activeHome.active === 0 || activeHome.prev === 0) && !loading
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Hero
