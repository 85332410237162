import Box from '@mui/material/Box'
import { useStateContext } from 'context/Context'
import BottomGrid from './BottomGrid'
import TopGrid from './TopGrid'

const SixthGrid = () => {
  const { activeHome } = useStateContext()
  const isActive = activeHome.active === 5 || activeHome.prev === 5
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
      }}
    >
      <Box />
      {/* <Button
        onClick={() => swiperMobileHome.slideTo(0)}
        variant="outlined"
        color="secondary"
        sx={{
          overflow: 'hidden',
          position: 'absolute',
          top: 0,
          left: 0,
          m: 2,
          zIndex: 1,
        }}
      >
        <Typography
          variant="h5"
          align="left"
          color="secondary"
          fontWeight="bold"
          className={` ${
            isActive
              ? 'animate__animated animate__fadeInLeft animate__delay-1s'
              : ''
          }`}
        >
          Services
        </Typography>
      </Button> */}
      <Box
        className={` ${
          isActive
            ? 'animate__animated animate__fadeInLeft animate__delay-1s'
            : ''
        }`}
      >
        <TopGrid isActive={isActive} />
      </Box>
      <Box
        className={` ${
          isActive
            ? 'animate__animated animate__fadeInRight animate__delay-1s'
            : ''
        }`}
      >
        <BottomGrid />
      </Box>
    </Box>
  )
}

export default SixthGrid
