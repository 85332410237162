import Box from '@mui/material/Box'
import hero from 'assets/Home/hero2.png'

const RightGrid = ({ isActive }) => {
  return (
    <Box
      className="height-100"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'var(--gradient)',
        '& img': {
          width: '80%',
          objectFit: 'contain',
        },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <img
          className={` ${
            isActive
              ? 'animate__animated animate__fadeInUp animate__delay-1s'
              : ''
          }`}
          src={hero}
          alt="hero"
        />
      </Box>
    </Box>
  )
}

export default RightGrid
