import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { useStateContext } from 'context/Context'
import LeftGrid from './LeftGrid'
import RightGrid from './RightGrid'

const Company = () => {
  const { activeHome } = useStateContext()
  return (
    <Box
      className="height-100"
      sx={{
        width: '100%',
        bgcolor: 'secondary.main',
      }}
    >
      <Grid
        container
        alignItems="stretch"
        sx={{
          height: '100%',
          width: '100%',
        }}
      >
        <Grid item sm={6}>
          <LeftGrid
            isActive={activeHome.active === 1 || activeHome.prev === 1}
          />
        </Grid>
        <Grid item sm={6}>
          <RightGrid
            isActive={activeHome.active === 1 || activeHome.prev === 1}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Company
