import { Button, Container, TextField } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import bg from 'assets/bg3.jpg'
import { useStateContext } from 'context/Context'

const ContactGrid = () => {
  const { activeHome, swiperMobileHome } = useStateContext()
  const isActive = activeHome.active === 7 || activeHome.prev === 7
  return (
    <Box
      className="height-100"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        flexDirection: 'column',
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          mt: 4,
        }}
      >
        <Button
          onClick={() => swiperMobileHome.slideTo(0)}
          variant="outlined"
          color="primary"
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: 0,
            m: 2,
            zIndex: 1,
          }}
        >
          <Typography
            variant="h5"
            align="left"
            color="primary"
            fontWeight="bold"
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
          >
            Contact Us
          </Typography>
        </Button>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            align="left"
            variant="h4"
            fontWeight="bold"
          >
            Contact Us
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
            sx={{
              width: 100,
              height: 4,
              bgcolor: 'primary.main',
            }}
          />
        </Box>

        <br />
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
          >
            <Typography align="left" variant="h6">
              Your Name
            </Typography>

            <TextField
              InputLabelProps={{
                sx: {
                  color: 'primary.main',
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: 1,
                  '&::before': {
                    width: 0,
                  },
                },
              }}
              fullWidth
              label="Your Name"
              variant="filled"
            />
          </Box>
        </Box>

        <br />
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
          >
            <Typography align="left" variant="h6">
              Your Email
            </Typography>
            <TextField
              InputLabelProps={{
                sx: {
                  color: 'primary.main',
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: 1,
                  '&::before': {
                    width: 0,
                  },
                },
              }}
              fullWidth
              label="Your Email"
              variant="filled"
            />
          </Box>
        </Box>
        <br />

        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
          >
            <Typography align="left" variant="h6">
              Your Phone Number
            </Typography>
            <TextField
              InputLabelProps={{
                sx: {
                  color: 'primary.main',
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: 1,
                  '&::before': {
                    width: 0,
                  },
                },
              }}
              fullWidth
              label="Your Phone Number"
              variant="filled"
            />
          </Box>
        </Box>

        <br />

        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Box
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
          >
            <Typography align="left" variant="h6">
              Your Message
            </Typography>
            <TextField
              InputLabelProps={{
                sx: {
                  color: 'primary.main',
                },
              }}
              InputProps={{
                sx: {
                  borderRadius: 1,
                  '&::before': {
                    width: 0,
                  },
                },
              }}
              fullWidth
              label="Your Message"
              variant="filled"
            />
          </Box>
        </Box>

        <br />
        <Box
          sx={{
            overflow: 'hidden',
            py: 0.3,
          }}
        >
          <Button
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInUp animate__delay-1s'
                : ''
            }`}
            size="large"
            fullWidth
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default ContactGrid
