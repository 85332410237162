import { Button, Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import bg from 'assets/uiux.jpg'

const BottomGrid = ({ isActive }) => {
  return (
    <Box
      className="height-50"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundImage: `linear-gradient(to left, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.9)), 
            url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          flexDirection: 'column',
          color: 'secondary.main',
        }}
      >
        <Container
          sx={{
            py: 2,
          }}
        >
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? 'animate__animated animate__fadeInDown animate__delay-1s'
                  : ''
              }`}
              align="left"
              variant="h4"
              fontWeight="medium"
              gutterBottom
            >
              Design <br />
              Solutions
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? 'animate__animated animate__fadeInDown animate__delay-1s'
                  : ''
              }`}
              sx={{ maxWidth: '45ch' }}
              align="left"
              variant="subtitle2"
              gutterBottom
            >
              Our bespoke website development service offers a distinctive
              design that is meticulously tailored to reflect the client's brand
              and vision.
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: 'hidden',
              pt: 2,
              pb: 0.5,
            }}
          >
            <Button
              className={` ${
                isActive
                  ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                  : ''
              }`}
              sx={{
                py: 0.7,
              }}
              variant="outlined"
              color="secondary"
              size="large"
            >
              <Box
                sx={{
                  height: 2.5,
                  width: 80,
                  background: 'var(--secondary)',
                }}
              />
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}
export default BottomGrid
