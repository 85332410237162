// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

// import Swiper core and required modules
import Box from '@mui/material/Box'
import Company from 'components/Home/Desktop/Company'
import FinalGrid from 'components/Home/Desktop/FinalGrid'
import Hero from 'components/Home/Desktop/Hero'
import Services from 'components/Home/Desktop/Services'
import { useStateContext } from 'context/Context'
// import { useRef } from 'react'
import SwiperCore, {
  Autoplay,
  EffectFade,
  Mousewheel,
  Pagination,
} from 'swiper'
import SlideControl from 'utils/SlideControl'
import ContactGrid from '../Desktop/Contact'

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Pagination, Mousewheel])

const Desktop = () => {
  const { activeHome, setActiveHome } = useStateContext()
  // const progressCircle = useRef(null)
  // const progressContent = useRef(null)
  // const onAutoplayTimeLeft = (s, time, progress) => {
  //   // console.log(1 - progress)
  //   progressCircle.current.style.setProperty('--progress', 1 - progress)
  //   progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
  // }
  return (
    <Box
      sx={{
        height: '100vh',
        transition: 'all 0.5s ease-in-out',
        width: '100%',
        '& .swiper-pagination .swiper-pagination-bullet': {
          transition: 'all 0.3s ease-in-out',
          background:
            activeHome.active === 0
              ? 'var(--primary)'
              : activeHome.active === 1
              ? 'var(--golden)'
              : '#fff',
        },
        '& .swiper-pagination .swiper-pagination-bullet-active': {
          transition: 'all 0.3s ease-in-out',
          background:
            activeHome.active === 0
              ? 'var(--primary)'
              : activeHome.active === 1
              ? 'var(--golden)'
              : '#fff',
          height: '30px',
          borderRadius: '10px',
        },
      }}
    >
      <Swiper
        // autoplay={{
        //   delay: 3000,
        //   disableOnInteraction: false,
        // }}
        speed={800}
        mousewheel={true}
        // grabCursor={true}
        // effect={'fade'}
        className="mySwiper"
        direction={'vertical'}
        pagination={{
          clickable: true,
        }}
        // onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSlideChange={(e) => {
          // const { activeIndex, snapIndex, previousIndex, realIndex } = e
          // console.log({ activeIndex, snapIndex, previousIndex, realIndex })
          setActiveHome({
            active: e.realIndex,
            prev: e.previousIndex,
          })
        }}
      >
        <SwiperSlide>
          <Hero />
        </SwiperSlide>
        <SwiperSlide>
          <Company />
        </SwiperSlide>
        <SwiperSlide>
          <Services />
        </SwiperSlide>
        <SwiperSlide>
          <ContactGrid />
        </SwiperSlide>
        <SwiperSlide>
          <FinalGrid />
        </SwiperSlide>
        {/* <Box className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </Box> */}
        <SlideControl />
      </Swiper>
    </Box>
  )
}

export default Desktop
