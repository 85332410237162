import { Button, Container, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

const SingleMobile = ({ title, img, isActive }) => {
  return (
    <Box
      sx={{
        bgcolor: 'secondary.main',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '50vw',
          height: '100svh',
          background: 'var(--gradient)',
        }}
      />
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Grid
          container
          alignItems="center"
          sx={{
            position: 'relative',
            zIndex: 1000,
          }}
        >
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  transition: 'all 0.5s ease-in-out',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img
                  className={` ${
                    isActive
                      ? 'animate__animated animate__fadeInUp animate__delay-1s image'
                      : 'image'
                  }`}
                  width="65%"
                  src={img}
                  alt="img"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                mt: { xs: 3, sm: 0 },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={{
                  overflow: 'hidden',
                }}
              >
                <Typography
                  variant="subtitle2"
                  className={` ${
                    isActive
                      ? 'animate__animated animate__fadeInDown animate__delay-1s'
                      : ''
                  }`}
                >
                  TECH TRENDS
                </Typography>
              </Box>
              <Box
                sx={{
                  overflow: 'hidden',
                }}
              >
                <Typography
                  align="center"
                  className={` ${
                    isActive
                      ? 'animate__animated animate__fadeInDown animate__delay-1s'
                      : ''
                  }`}
                  variant="h4"
                  fontWeight="bold"
                  gutterBottom
                >
                  <span>{title}</span>
                </Typography>
              </Box>
              <Box
                sx={{
                  overflow: 'hidden',
                }}
              >
                <Typography
                  className={` ${
                    isActive
                      ? 'animate__animated animate__fadeInDown animate__delay-1s'
                      : ''
                  }`}
                  gutterBottom
                  align="center"
                  variant="subtitle1"
                >
                  <span>
                    Look Towards the Future With Diggy, the App That Will Change
                    All. For a New Reality.
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  overflow: 'hidden',
                  p: 0.2,
                }}
              >
                <Button
                  fullWidth
                  className={` ${
                    isActive
                      ? 'animate__animated animate__fadeInDown animate__delay-1s'
                      : ''
                  }`}
                  variant="contained"
                  color="primary"
                  sx={{
                    mt: 1,
                    fontWeight: 800,
                    px: 5,
                    '& span': {
                      position: 'relative',
                      zIndex: 2,
                    },
                  }}
                >
                  <span>Visit Site</span>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default SingleMobile
