import Box from "@mui/material/Box"
import { useStateContext } from "context/Context"
import "loader.css"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const Preloader = () => {
  const location = useLocation()
  const { loading, setLoading } = useStateContext()
  useEffect(() => {
    setLoading(true)
    const second = setTimeout(() => {
      setLoading(false)
    }, 1800)
    return () => {
      clearTimeout(second)
    }
  }, [setLoading, location.pathname])

  return (
    <Box>
      <Box
        className="height-100"
        sx={{
          overflowY: "hidden !important",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 9999,
          display: "flex",
          transition:
            "all 0.5s ease-in-out, background-color var(--trans-dur), color var(--trans-dur)",
          opacity: loading ? 1 : 0,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "var(--bg) !important",
          backgroundImage:
            "linear-gradient(135deg,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.2)) !important",
          color: "var(--fg) !important",
          font: "1em/1.5 'DM Sans', Helvetica, sans-serif",

          minHeight: "360px",
          pointerEvents: "none",
          fontSize: "calc(16px + (20 - 16) * (100vw - 320px) / (1280 - 320))",
        }}
      >
        <div className="pl">
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__dot"></div>
          <div className="pl__text">QuCode</div>
        </div>
      </Box>
    </Box>
  )
}

export default Preloader
