import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import four from 'assets/Home/four.svg'

const BottomGrid = () => {
  return (
    <Box
      className="height-50"
      sx={{
        width: '100%',
        bgcolor: 'var(--secondary)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& img': {
          height: 80,
        },
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={four} alt="four" />
        <br />
        <Typography variant="h6" align="center" gutterBottom fontWeight="bold">
          Cloud <br />
          Services
        </Typography>
        <Typography align="center" variant="subtitle2">
          Providing cloud-based infrastructure, storage, monitoring and
          analytics, cloud migration & related services to businesses.
        </Typography>
      </Container>
    </Box>
  )
}

export default BottomGrid
