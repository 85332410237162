import { Grid } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import FocusArea from "./FocusArea"
import Header from "./Header"
import Social from "./Social"

const Footer = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        bgcolor: "primary.main",
        pt: 10,
        px: 10,
        color: "secondary.main",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Grid
        sx={{
          pb: 10,
        }}
        container
        alignItems="stretch"
        justifyContent="center"
      >
        <Grid item xs={12} sm={4}>
          <Header />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FocusArea />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Social />
        </Grid>
      </Grid>

      <Typography
        align="center"
        sx={{ typography: { xs: "subtitle2", sm: "h6" } }}
      >
        Copyright © {new Date().getFullYear()}. All rights reserved by QuCode
        Company.
      </Typography>
    </Box>
  )
}

export default Footer
