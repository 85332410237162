import { Container } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import two from 'assets/Home/two.svg'

const BottomGrid = () => {
  return (
    <Box
      className="height-50"
      sx={{
        width: '100%',
        bgcolor: 'var(--secondary)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& img': {
          height: 80,
        },
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img src={two} alt="img" />
        <br />
        <Typography variant="h6" align="center" gutterBottom fontWeight="bold">
          Android/IOS <br />
          Applications
        </Typography>
        <Typography align="center" variant="subtitle2">
          Android & iOS application services including design, development,
          testing, deployment, ongoing maintenance & support.
        </Typography>
      </Container>
    </Box>
  )
}

export default BottomGrid
