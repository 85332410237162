import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import TopLeft from './TopLeft'
import TopRight from './TopRight'

const TopGrid = ({ isActive }) => {
  return (
    <Box className="height-50">
      <Grid
        container
        sx={{
          height: '100%',
        }}
      >
        <Grid item xs={6}>
          <TopLeft isActive={isActive} />
        </Grid>
        <Grid item xs={6}>
          <TopRight isActive={isActive} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default TopGrid
