import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'

const ChooseBlock = ({ title, isActive }) => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography
        className={` ${
          isActive
            ? 'animate__animated animate__fadeInUp animate__delay-1s image'
            : 'image'
        }`}
        variant="subtitle2"
        align="center"
        gutterBottom
      >
        {title}
      </Typography>
    </Box>
  )
}

export default ChooseBlock
