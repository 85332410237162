// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

// import Swiper core and required modules
import Box from '@mui/material/Box'
import FifthGrid from 'components/Home/Mobile/FifthGrid'
import FirstGrid from 'components/Home/Mobile/FirstGrid'
import SecondGrid from 'components/Home/Mobile/SecondGrid'
import SeventhGrid from 'components/Home/Mobile/SeventhGrid'
import SixthGrid from 'components/Home/Mobile/SixthGrid'
import { useStateContext } from 'context/Context'
// import { useRef } from 'react'
import SwiperCore, {
  Autoplay,
  EffectFade,
  Mousewheel,
  Pagination,
} from 'swiper'
import SlideControlMobile from 'utils/SlideControlMobile'
import AboutGrid from '../Mobile/Expertise'
import CEOGrid from '../Mobile/CEOGrid'
import ContactGrid from '../Mobile/ContactGrid'
import WhyChooseUs from '../Mobile/WhyChooseUs'

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Pagination, Mousewheel])

const Mobile = () => {
  const { activeHome, setActiveHome, setSwiperMobileHome } = useStateContext()
  // const progressCircle = useRef(null)
  // const progressContent = useRef(null)
  // const onAutoplayTimeLeft = (s, time, progress) => {
  //   // console.log(1 - progress)
  //   progressCircle.current.style.setProperty('--progress', 1 - progress)
  //   progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
  // }
  return (
    <Box
      className="height-100"
      sx={{
        transition: 'all 0.5s ease-in-out',
        width: '100%',

        '& .swiper-pagination .swiper-pagination-bullet': {
          transition: 'all 0.3s ease-in-out',
          background:
            activeHome.active === 0
              ? 'var(--golden)'
              : activeHome.active === 1
              ? 'var(--primary)'
              : activeHome.active === 2
              ? 'var(--secondary)'
              : activeHome.active === 3
              ? 'var(--primary)'
              : activeHome.active === 4
              ? 'var(--secondary)'
              : activeHome.active === 5
              ? 'var(--golden)'
              : activeHome.active === 6
              ? 'var(--golden)'
              : activeHome.active === 7
              ? 'var(--primary)'
              : activeHome.active === 8
              ? 'var(--golden)'
              : '#fff',
        },
        '& .swiper-pagination .swiper-pagination-bullet-active': {
          transition: 'all 0.3s ease-in-out',
          background:
            activeHome.active === 0
              ? 'var(--golden)'
              : activeHome.active === 1
              ? 'var(--primary)'
              : activeHome.active === 2
              ? 'var(--secondary)'
              : activeHome.active === 3
              ? 'var(--primary)'
              : activeHome.active === 4
              ? 'var(--secondary)'
              : activeHome.active === 5
              ? 'var(--golden)'
              : activeHome.active === 6
              ? 'var(--golden)'
              : activeHome.active === 7
              ? 'var(--primary)'
              : activeHome.active === 8
              ? 'var(--golden)'
              : '#fff',
          height: '30px',
          // width: '8px',
          borderRadius: '10px',
        },
      }}
    >
      <Swiper
        // autoplay={{
        //   delay: 3000,
        //   disableOnInteraction: false,
        // }}
        speed={800}
        mousewheel={true}
        // grabCursor={true}
        // effect={'fade'}
        className="mySwiper"
        direction={'vertical'}
        edgeSwipeDetection={true}
        edgeSwipeThreshold={10}
        pagination={{
          clickable: true,
        }}
        onSwiper={(swiper) => setSwiperMobileHome(swiper)}
        // onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSlideChange={(e) => {
          // const { activeIndex, snapIndex, previousIndex, realIndex } = e
          // console.log({ activeIndex, snapIndex, previousIndex, realIndex })
          setActiveHome({
            active: e.realIndex,
            prev: e.previousIndex,
          })
        }}
      >
        <SwiperSlide>
          <FirstGrid />
        </SwiperSlide>
        {/* <SwiperSlide>
          <SecondGrid />
        </SwiperSlide> */}
        <SwiperSlide>
          <SecondGrid />
        </SwiperSlide>

        <SwiperSlide>
          <WhyChooseUs />
        </SwiperSlide>
        <SwiperSlide>
          <AboutGrid />
        </SwiperSlide>

        <SwiperSlide>
          <FifthGrid />
        </SwiperSlide>
        <SwiperSlide>
          <SixthGrid />
        </SwiperSlide>
        <SwiperSlide>
          <SeventhGrid />
        </SwiperSlide>
        <SwiperSlide>
          <ContactGrid />
        </SwiperSlide>
        <SwiperSlide>
          <CEOGrid />
        </SwiperSlide>
        {/* <Box className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
          <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </Box> */}
        <SlideControlMobile />
      </Swiper>
    </Box>
  )
}

export default Mobile
