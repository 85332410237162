import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import { Button, Drawer, IconButton, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import logo from "assets/logoO.svg"
import { Divide as Hamburger } from "hamburger-react"
import { useState } from "react"
import { Link } from "react-router-dom"

const navItems = [
  { name: "Home", to: "/" },
  // { name: 'About', to: '/about' },
  // { name: "Project", to: "/project" },
  { name: "Contact", to: "/contact" },
]

const SideBar = ({ activeHome }) => {
  const [open, setOpen] = useState(false)

  const handleDrawerToggle = () => {
    setOpen((prev) => !prev)
  }

  const handleClose = (value) => {
    setOpen(value)
  }

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        width: {
          xs: "100vw",
          sm: 300,
          md: 350,
        },
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            fontWeight: 500,
            fontSize: "1.5rem",
            my: 3.5,
            px: 3,
            display: "flex",

            justifyContent: "flex-start",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <img
            src={logo}
            alt="logo"
            style={{
              height: "40px",
            }}
          />
        </Box>

        <Box
          sx={{
            height: 1.5,
            width: "100%",
            backgroundColor: "var(--golden)",
          }}
        />
        <List
          sx={{
            width: "100%",
          }}
        >
          {navItems.map((item) => (
            <ListItem
              sx={{
                width: "100%",
                backgroundColor: "var(--golden)",
                my: 2,
              }}
              key={item.name}
              disablePadding
            >
              <ListItemButton
                component={Link}
                to={item.to}
                sx={{ textAlign: "center", width: "100%" }}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: 1.5,
            width: "100%",
            backgroundColor: "var(--golden)",
          }}
        />
        <br />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ListItem disablePadding>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              component="a"
              href="https://www.facebook.com/rgbjute/"
              target="blank"
              sx={{ borderRadius: 0, mr: 0.5 }}
            >
              <FacebookIcon />
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              component="a"
              href="https://www.instagram.com/rgbjute/"
              target="blank"
              sx={{ borderRadius: 0, mr: 0.5 }}
            >
              <InstagramIcon />
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              component="a"
              href="https://www.linkedin.com/company/rgbjute"
              target="blank"
              sx={{ borderRadius: 0, mr: 0.5 }}
            >
              <LinkedInIcon />
            </Button>
            <Button
              variant="outlined"
              size="small"
              color="secondary"
              component="a"
              href="https://wa.me/+8801515663970"
              target="blank"
              sx={{ borderRadius: 0 }}
            >
              <WhatsAppIcon />
            </Button>
          </ListItem>
          <ListItem sx={{ mt: 1, width: "100%" }}>
            <Typography
              align="center"
              sx={{
                width: "100%",
              }}
            >
              Developed by{" "}
              <a
                style={{
                  color: "var(--golden)",
                  fontWeight: "bold",
                }}
                href="https://qucode.tech"
              >
                QuCode
              </a>
            </Typography>
          </ListItem>
        </Box>
      </Box>
    </Box>
  )

  return (
    <div>
      <IconButton size="small" onClick={handleDrawerToggle}>
        <Box>
          <Hamburger
            toggled={open}
            size={30}
            color={open ? "#e8b892" : "#ffffff"}
          />
        </Box>
      </IconButton>
      <Drawer
        sx={{
          zIndex: "500 !important",
        }}
        PaperProps={{
          sx: {
            backgroundColor: "primary.main",
            color: "secondary.main",
          },
        }}
        anchor="right"
        variant="temporary"
        open={open}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        onClose={() => handleClose(false)}
        onOpen={() => handleDrawerToggle(true)}
      >
        {drawer}
      </Drawer>
    </div>
  )
}
export default SideBar
