import { createContext, useContext, useState } from 'react'

export const Context = createContext()

export const ContextProvider = ({ children }) => {
  const [darkmode, setDarkmode] = useState(true)
  const [activeHome, setActiveHome] = useState({
    active: 0,
    prev: 0,
  })
  const [loading, setLoading] = useState(true)
  const [swiperMobileHome, setSwiperMobileHome] = useState(null)

  return (
    <Context.Provider
      value={{
        darkmode,
        setDarkmode,
        activeHome,
        setActiveHome,
        loading,
        setLoading,
        swiperMobileHome,
        setSwiperMobileHome,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useStateContext = () => useContext(Context)
