import { Grid } from "@mui/material"
import Box from "@mui/material/Box"
import logo from "assets/logoBlack.svg"
import Footer from "components/Footer"
import { useStateContext } from "context/Context"
import Confetti from "react-confetti"
import TopGrid from "./TopGrid"

const FinalGrid = () => {
  // const [confetti, setConfetti] = useState(true)
  const { activeHome } = useStateContext()
  // useEffect(() => {
  //   setConfetti(true)
  //   const second = setTimeout(() => {
  //     setConfetti(false)
  //   }, 3000)
  //   return () => {
  //     clearTimeout(second)
  //   }
  // }, [activeHome])
  const isActive = activeHome.active === 4 || activeHome.prev === 4
  return (
    <Box
      className="height-100"
      sx={{
        width: "100%",
      }}
    >
      {isActive && <Confetti recycle={false} numberOfPieces={800} />}
      <Box
        sx={{
          position: "absolute",
          m: 2,
        }}
      >
        <img
          src={logo}
          alt="logo"
          className={` ${
            isActive
              ? "animate__animated animate__fadeInLeft animate__delay-1s"
              : ""
          }`}
          style={{
            height: 40,
          }}
        />
      </Box>
      <Grid
        container
        direction="column"
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          item
          xs
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <TopGrid isActive={isActive} />
        </Grid>
        <Grid
          item
          xs
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Footer />
        </Grid>
      </Grid>
    </Box>
  )
}

export default FinalGrid
