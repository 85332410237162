import { Hidden } from '@mui/material'
import Box from '@mui/material/Box'
import Desktop from 'components/Home/Carousel/Desktop'
import Mobile from 'components/Home/Carousel/Mobile'

const Home = () => {
  return (
    <Box>
      <Hidden smDown>
        <Desktop />
      </Hidden>
      <Hidden smUp>
        <Mobile />
      </Hidden>
    </Box>
  )
}

export default Home
