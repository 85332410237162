import Box from '@mui/material/Box'
import { useStateContext } from 'context/Context'
import BottomGrid from './BottomGrid'
import TopGrid from './TopGrid'

const FifthGrid = () => {
  const { activeHome } = useStateContext()
  const isActive = activeHome.active === 4 || activeHome.prev === 4
  return (
    <Box className="height-100">
      <TopGrid isActive={isActive} />
      <BottomGrid isActive={isActive} />
    </Box>
  )
}

export default FifthGrid
