import { Container, Divider, Stack } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useStateContext } from 'context/Context'

const WhyChooseUs = () => {
  const { activeHome } = useStateContext()
  const isActive = activeHome.active === 2 || activeHome.prev === 2
  return (
    <Box
      className="height-100"
      sx={{
        color: 'secondary.main',
        bgcolor: 'primary.main',

        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Container
        sx={{
          py: 2.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <Box />
        {/* <Button
          onClick={() => swiperMobileHome.slideTo(0)}
          variant="outlined"
          color="secondary"
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            variant="h5"
            align="left"
            color="secondary"
            fontWeight="bold"
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
          >
            About Us
          </Typography>
        </Button> */}
        <Box sx={{ width: '100%' }}>
          <Divider
            sx={{
              mb: 2,
              bgcolor: 'secondary.main',
            }}
            orientation="horizontal"
            flexItem
          />
          <Stack
            sx={{
              height: '100%',
              width: '100%',
            }}
            direction="column"
            divider={
              <Divider
                sx={{
                  bgcolor: 'secondary.main',
                }}
                orientation="horizontal"
                flexItem
              />
            }
            spacing={2}
          >
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                align="center"
                variant="h5"
                fontWeight="bold"
              >
                Why Choose Us?
              </Typography>
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                align="center"
                variant="subtitle2"
              >
                Building custom-made software for individual clients.
              </Typography>
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                align="center"
                variant="subtitle2"
              >
                Committed to provide high-quality software and apps.
              </Typography>
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                align="center"
                variant="subtitle2"
              >
                Highly skilled engineers & excellent technical mastery.
              </Typography>
            </Box>
            <Box
              sx={{
                overflow: 'hidden',
              }}
            >
              <Typography
                className={` ${
                  isActive
                    ? 'animate__animated animate__fadeInDown animate__delay-1s'
                    : ''
                }`}
                align="center"
                variant="subtitle2"
              >
                Always focus on providing excellent customer service.
              </Typography>
            </Box>
          </Stack>
          <Divider
            sx={{
              bgcolor: 'secondary.main',
            }}
            orientation="horizontal"
            flexItem
          />
        </Box>
        <Box />
      </Container>
    </Box>
  )
}

export default WhyChooseUs
