import { Button, Container } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import logo from "assets/logo.svg"
import { Link } from "react-router-dom"
import { useSwiper } from "swiper/react"

const LeftGrid = ({ isActive }) => {
  const swiper = useSwiper()
  return (
    <Box
      className="height-100"
      sx={{
        color: "secondary.main",
        bgcolor: "primary.main",
      }}
    >
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          component={Link}
          to="/"
          sx={{
            mt: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className={` ${
              isActive
                ? "animate__animated animate__fadeInLeft animate__delay-1s"
                : ""
            }`}
            style={{
              height: 40,
            }}
          />
        </Box>
        <Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              // id="text-anim"
              // component={motion.div}
              // variants={textShowVariant}
              align="left"
              variant="subtitle1"
              gutterBottom
            >
              CONCEPT TO CREATION
            </Typography>
          </Box>

          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              sx={{
                typography: { xs: "h4", sm: "h2" },
              }}
            >
              Innovators of
            </Typography>
          </Box>
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              align="left"
              sx={{
                typography: { xs: "h4", sm: "h2" },
              }}
              gutterBottom
            >
              Software solutions
            </Typography>
          </Box>
          <Box p={1} />
          <Box
            sx={{
              overflow: "hidden",
              py: 0.5,
            }}
          >
            <Button
              onClick={() => swiper.slideTo(2)}
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s"
                  : ""
              }`}
              variant="contained"
              color="secondary"
              size="large"
              sx={{
                // opacity: 0,
                fontWeight: 800,
                // overflow: 'hidden',
                // transform: 'translateY(150px)',
                background: "var(--gradient)",
                // '& span': {
                //   position: 'relative',
                //   zIndex: 2,
                // },
              }}
            >
              <span>Our Services</span>
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            pb: 1,
          }}
        >
          <Box
            sx={{
              overflow: "hidden",
            }}
          >
            <Typography
              className={` ${
                isActive
                  ? "animate__animated animate__fadeInDown animate__delay-1s text-gradient"
                  : "text-gradient"
              }`}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              align="left"
              variant="h6"
              gutterBottom
              component="a"
              href="mailto:contact@qucode.tech"
              target="_blank"
            >
              contact@qucode.tech
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default LeftGrid
