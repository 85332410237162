// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"
import "swiper/css/pagination"

// import Swiper core and required modules
import { Hidden, useMediaQuery } from "@mui/material"
import Box from "@mui/material/Box"
import Single from "components/Project/Single"
import { useRef } from "react"
import SwiperCore, {
  Autoplay,
  EffectFade,
  Mousewheel,
  Pagination,
} from "swiper"

import iphone from "assets/Showcase/iphone.png"
import mac from "assets/Showcase/mac.png"
import SingleMobile from "components/Project/SingleMobile"
import { useStateContext } from "context/Context"

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Pagination, Mousewheel])

const Project = () => {
  const { activeHome, setActiveHome } = useStateContext()
  const progressCircle = useRef(null)
  const progressContent = useRef(null)
  const onAutoplayTimeLeft = (s, time, progress) => {
    console.log(1 - progress)
    progressCircle.current.style.setProperty("--progress", 1 - progress)
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
  }

  const isSmall = useMediaQuery("(max-width:600px)")
  return (
    <Box
      sx={{
        height: "100svh",
        transition: "all 0.5s ease-in-out",
        width: "100%",
        "& .swiper-pagination .swiper-pagination-bullet": {
          transition: "all 0.3s ease-in-out",
          background: "#2b2b2b",
        },
        "& .swiper-pagination .swiper-pagination-bullet-active": {
          transition: "all 0.3s ease-in-out",
          background: "#2b2b2b",
          height: { xs: "8px", sm: "30px" },
          width: { xs: "30px", sm: "8px" },
          borderRadius: "10px",
        },
      }}
    >
      <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={1500}
        loop
        mousewheel={true}
        // grabCursor={true}
        effect={"fade"}
        slidesPerView={1}
        className="mySwiper"
        direction={isSmall ? "horizontal" : "vertical"}
        pagination={{
          clickable: true,
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        onSlideChange={(e) => {
          // const { activeIndex, snapIndex, previousIndex, realIndex } = e
          // console.log({ activeIndex, snapIndex, previousIndex, realIndex })
          setActiveHome(e.realIndex)
        }}
      >
        <SwiperSlide>
          <Hidden smDown>
            <Single title="Amazing App" img={mac} isActive={activeHome === 0} />
          </Hidden>
          <Hidden smUp>
            <SingleMobile
              title="Amazing App"
              img={mac}
              isActive={activeHome === 0}
            />
          </Hidden>
        </SwiperSlide>
        <SwiperSlide>
          <Hidden smDown>
            <Single
              title="Mobile App"
              img={iphone}
              isActive={activeHome === 1}
            />
          </Hidden>
          <Hidden smUp>
            <SingleMobile
              title="Amazing App"
              img={iphone}
              isActive={activeHome === 1}
            />
          </Hidden>
        </SwiperSlide>
        <SwiperSlide>
          <Hidden smDown>
            <Single title="Amazing App" img={mac} isActive={activeHome === 2} />
          </Hidden>
          <Hidden smUp>
            <SingleMobile
              title="Amazing App"
              img={mac}
              isActive={activeHome === 2}
            />
          </Hidden>
        </SwiperSlide>
        <SwiperSlide>
          <Hidden smDown>
            <Single
              title="Mobile App"
              img={iphone}
              isActive={activeHome === 3}
            />
          </Hidden>
          <Hidden smUp>
            <SingleMobile
              title="Amazing App"
              img={iphone}
              isActive={activeHome === 3}
            />
          </Hidden>
        </SwiperSlide>

        <Box className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </Box>
      </Swiper>
    </Box>
  )
}

export default Project
