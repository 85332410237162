import { Hidden } from '@mui/material'
import ContactDesktop from 'components/Contact/Desktop'
import ContactMobile from 'components/Contact/Mobile'

const Contact = () => {
  return (
    <>
      <Hidden smDown>
        <ContactDesktop />
      </Hidden>
      <Hidden smUp>
        <ContactMobile />
      </Hidden>
    </>
  )
}

export default Contact
