import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const TopLeft = ({ isActive }) => {
  return (
    <Box
      sx={{
        background: 'var(--gradient)',
        height: '100%',
        width: '100%',
        p: { xs: 3, sm: 3 },
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Button
        variant="outlined"
        color="primary"
        sx={{
          overflow: 'hidden',
        }}
      >
        <Typography
          variant="h4"
          align="left"
          color="primary"
          fontWeight="bold"
          className={` ${
            isActive
              ? 'animate__animated animate__fadeInLeft animate__delay-1s'
              : ''
          }`}
        >
          Services
        </Typography>
      </Button>
      <Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            align="left"
            variant="h4"
            fontWeight="medium"
            gutterBottom
          >
            Custom Website <br /> Development
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            sx={{ maxWidth: '50ch' }}
            align="left"
            variant="subtitle1"
            gutterBottom
          >
            Our bespoke website development service offers a distinctive design
            that is meticulously tailored to reflect the client's brand and
            vision.
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
            pt: 2,
            pb: 0.5,
          }}
        >
          <Button
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
            sx={{
              py: 0.7,
            }}
            variant="outlined"
            color="primary"
            size="large"
          >
            <Box
              sx={{
                height: 2.5,
                width: 80,
                background: 'var(--primary)',
              }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default TopLeft
