import { Grid } from '@mui/material'
import Box from '@mui/material/Box'
import { useStateContext } from 'context/Context'
import LeftGrid from './LeftGrid'
import RightGrid from './RightGrid'

const ContactGrid = () => {
  const { activeHome } = useStateContext()
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <LeftGrid
            isActive={activeHome.active === 3 || activeHome.prev === 3}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RightGrid
            isActive={activeHome.active === 3 || activeHome.prev === 3}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ContactGrid
