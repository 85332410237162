import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useSwiper } from 'swiper/react'

const BottomRight = ({ isActive }) => {
  const swiper = useSwiper()
  return (
    <Box
      sx={{
        bgcolor: 'primary.main',
        color: 'secondary.main',
        p: { xs: 3, sm: 5 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            align="left"
            sx={{
              typography: {
                xs: 'h5',
                sm: 'h4',
              },
            }}
            gutterBottom
            fontWeight="bold"
          >
            Expertise you can rely on
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            align="left"
            variant="subtitle1"
          >
            Our expertise lies in leveraging the latest technologies and tools
            to create efficient, scalable, and secure software solutions that
            enhance business performance and improve customer experience. We
            have experience in a range of industries, including healthcare,
            finance, education, e-commerce, fashion and more.
          </Typography>
        </Box>
        <br />
        <Box
          sx={{
            overflow: 'hidden',
            py: 1,
          }}
        >
          <Button
            onClick={() => swiper.slideTo(3)}
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            variant="outlined"
            color="secondary"
            size="large"
          >
            Contact Us
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default BottomRight
