// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

// import Swiper core and required modules
import Box from '@mui/material/Box'
import SingleLeft from 'components/About/SingleLeft'
import SingleRight from 'components/About/SingleRight'
import { useStateContext } from 'context/Context'
import { useRef } from 'react'
import SwiperCore, {
  Autoplay,
  EffectFade,
  Mousewheel,
  Pagination,
} from 'swiper'

// install Swiper modules
SwiperCore.use([Autoplay, EffectFade, Pagination, Mousewheel])

const About = () => {
  const { setActiveHome } = useStateContext()
  const progressCircle = useRef(null)
  const progressContent = useRef(null)
  const onAutoplayTimeLeft = (s, time, progress) => {
    console.log(1 - progress)
    progressCircle.current.style.setProperty('--progress', 1 - progress)
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`
  }
  return (
    <Box
      sx={{
        height: '100dvh',
        transition: 'all 0.5s ease-in-out',
        width: '100%',
        '& .swiper-pagination .swiper-pagination-bullet': {
          transition: 'all 0.3s ease-in-out',
          background: '#ffffff',
        },
        '& .swiper-pagination .swiper-pagination-bullet-active': {
          transition: 'all 0.3s ease-in-out',
          background: '#ffffff',
          height: { xs: '8px', sm: '30px' },
          width: { xs: '30px', sm: '8px' },
          borderRadius: '10px',
        },
      }}
    >
      <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        speed={1000}
        loop
        mousewheel={true}
        // grabCursor={true}
        // effect={'fade'}
        className="mySwiper"
        direction={'vertical'}
        pagination={{
          clickable: true,
        }}
        onSlideChange={(e) => {
          // const { activeIndex, snapIndex, previousIndex, realIndex } = e
          // console.log({ activeIndex, snapIndex, previousIndex, realIndex })
          setActiveHome({
            active: e.realIndex,
            prev: e.previousIndex,
          })
        }}
        onAutoplayTimeLeft={onAutoplayTimeLeft}
      >
        <SwiperSlide>
          <SingleLeft />
        </SwiperSlide>
        <SwiperSlide>
          <SingleRight />
        </SwiperSlide>
        <SwiperSlide>
          <SingleLeft />
        </SwiperSlide>
        <SwiperSlide>
          <SingleRight />
        </SwiperSlide>
        <Box className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent}></span>
        </Box>
      </Swiper>
    </Box>
  )
}

export default About
