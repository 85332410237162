import { Button, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import bg from 'assets/uiux.jpg'

const TopRight = ({ isActive }) => {
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-end',
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          backgroundImage: `linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9)), 
            url(${bg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-start',
          flexDirection: 'column',
          color: 'secondary.main',
          p: 3,
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            align="left"
            variant="h4"
            fontWeight="medium"
            gutterBottom
          >
            Design <br />
            Solutions
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
          }}
        >
          <Typography
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            sx={{ maxWidth: '50ch' }}
            align="left"
            variant="subtitle1"
            gutterBottom
          >
            Our bespoke website development service offers a distinctive design
            that is meticulously tailored to reflect the client's brand and
            vision.
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: 'hidden',
            pt: 2,
            pb: 0.5,
          }}
        >
          <Button
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInLeft animate__delay-1s'
                : ''
            }`}
            sx={{
              py: 0.7,
            }}
            variant="outlined"
            color="secondary"
            size="large"
          >
            <Box
              sx={{
                height: 2.5,
                width: 80,
                background: 'var(--secondary)',
              }}
            />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default TopRight
