import { Button } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import secondGrid from "assets/Home/2ndGrid.jpg"

const LeftGrid = ({ isActive }) => {
  return (
    <Box
      className="height-100"
      sx={{
        width: "100%",
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgb(242, 244, 248)),url(${secondGrid})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        alignItems: "flex-start",
        p: { xs: 3, sm: 3 },
      }}
    >
      <Button
        variant="outlined"
        color="secondary"
        sx={{
          overflow: "hidden",
        }}
      >
        <Typography
          variant="h4"
          align="left"
          color="secondary"
          fontWeight="bold"
          className={` ${
            isActive
              ? "animate__animated animate__fadeInLeft animate__delay-1s"
              : ""
          }`}
        >
          About Us
        </Typography>
      </Button>

      <Box>
        <Box
          sx={{
            overflow: "hidden",
          }}
        >
          <Typography
            className={` ${
              isActive
                ? "animate__animated animate__fadeInDown animate__delay-1s"
                : ""
            }`}
          >
            DIGITAL SOLUTIONS
          </Typography>
        </Box>

        <Box
          sx={{
            overflow: "hidden",
          }}
        >
          <Typography
            className={` ${
              isActive
                ? "animate__animated animate__fadeInDown animate__delay-1s"
                : ""
            }`}
            sx={{
              typography: {
                xs: "h4",
                sm: "h3",
              },
            }}
            gutterBottom
          >
            Let's build the <br /> Digital future together.
          </Typography>
        </Box>
        <Box
          sx={{
            overflow: "hidden",
          }}
        >
          <Typography
            className={` ${
              isActive
                ? "animate__animated animate__fadeInDown animate__delay-1s"
                : ""
            }`}
            sx={{
              typography: {
                xs: "subtitle1",
              },
            }}
            gutterBottom
          >
            QuCode is a fast-growing startup and software development company
            specializing in delivering personalized, and tailored services to
            the clients across various industries. We help businesses to improve
            their operational efficiency, enhance customer experience, and drive
            business growth.
          </Typography>
        </Box>
        <br />
        {/* <Box
          sx={{
            overflow: 'hidden',
            py: 1,
          }}
        >
          <Button
            className={` ${
              isActive
                ? 'animate__animated animate__fadeInDown animate__delay-1s'
                : ''
            }`}
            variant="contained"
            color="primary"
            size="large"
          >
            Explore Now
          </Button>
        </Box> */}
      </Box>
    </Box>
  )
}

export default LeftGrid
